import * as React from "react";
import { TimelineLite } from "gsap";
import { Container } from "components/container/Container";
import { Row } from "components/row/Row";
import { Image } from "components/image/Image";
import { useInView } from "react-intersection-observer";

import s from "./Location.scss";

interface ILocationProps {
  title: string;
  text: React.ReactNode;
  children?: React.ReactNode;
}

export const Location = ({ title, text, children }: ILocationProps) => {
  const refTitle = React.useRef<HTMLHeadingElement>(null);
  const refImage = React.useRef<HTMLDivElement>(null);
  const refText = React.useRef<HTMLDivElement>(null);

  const [ref, inView] = useInView({ triggerOnce: true, rootMargin: "-5% 0%" });

  React.useEffect(() => {
    if (inView) {
      const t = new TimelineLite();

      t.addLabel("start", 0.5);

      if (refTitle.current) {
        t.fromTo(
          refTitle.current,
          1,
          { autoAlpha: 0, y: "40px" },
          { autoAlpha: 1, y: "0px", ease: "Power4.easeInOut" },
          "start"
        );
      }

      if (refImage.current) {
        t.fromTo(
          refImage.current,
          1.3,
          { autoAlpha: 0, y: "40px" },
          { autoAlpha: 1, y: "0px", ease: "Power4.easeInOut" },
          "start+=0.1"
        );
      }

      if (refText.current) {
        t.fromTo(
          refText.current,
          1.2,
          { autoAlpha: 0, y: "40px" },
          { autoAlpha: 1, y: "0px", ease: "Power4.easeInOut" },
          "start+=0.3"
        );
      }
    }
  }, [inView]);

  return (
    <Container>
      <div className={s.location} ref={ref}>
        <Row>
          <div className={s.location__col}>
            <h1 className={s.location__heading} ref={refTitle}>
              {title}
            </h1>
            <div className={s.location__taplist} ref={refImage}>
              <div id="menu-container" />
            </div>
          </div>

          <div className={s.location__text} ref={refText}>
            {text}
          </div>
        </Row>

        <Row>
          <div className={s.location__colMiddle}>
            <Image
              className={s.location__image}
              src={require("assets/images/tap02.jpg")}
            />
          </div>
          <div className={s.location__colLeft}>
            <Image
              className={s.location__image}
              src={require("assets/images/tap01.jpg")}
            />
          </div>
        </Row>

        <Row>
          <div className={s.location__colRight}>
            <a
              href="https://www.google.com/maps/place/Malbygg+Taproom/@64.1399974,-21.8482579,17z/data=!3m1!4b1!4m5!3m4!1s0x48d6757a5525ee31:0xdbb0cf1d3bd28bd6!8m2!3d64.1399951!4d-21.8460639"
              target="_blank"
            >
              <Image
                className={s.tap__image}
                src={require("assets/images/map.png")}
              />
            </a>
          </div>
        </Row>
      </div>
    </Container>
  );
};
