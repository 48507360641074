import * as React from "react";
import { withPrefix } from "gatsby";
import Helmet from "react-helmet";

import { Tap } from "components/tap/Tap";
import { Location } from "components/location/Location";

export default () => (
  <>
    <Helmet title="Taproom">
      <script src={withPrefix("untappd.js")} type="text/javascript" />
    </Helmet>

    <Tap
      title="Malbygg Taproom"
      text={
        <React.Fragment>
          <p>
            At Malbygg Taproom you can choose from eight different Malbygg beers
            on tap. We replace them regularly so freshly produced beer is always
            available. You can also buy a tasting tray with four types of our
            beers.
          </p>
        </React.Fragment>
      }
    />

    <Location title="Tap List" />
  </>
);
