import * as React from "react";
import { TimelineLite } from "gsap";
import { Container } from "components/container/Container";
import { Row } from "components/row/Row";
import { Image } from "components/image/Image";
import { useInView } from "react-intersection-observer";

import s from "./Tap.scss";

interface ITapProps {
  title: string;
  text: React.ReactNode;
  children: React.ReactNode;
}

export const Tap = ({ title, text, children }: ITapProps) => {
  const refTitle = React.useRef<HTMLHeadingElement>(null);
  const refImage = React.useRef<HTMLDivElement>(null);
  const refText = React.useRef<HTMLDivElement>(null);

  const [ref, inView] = useInView({ triggerOnce: true, rootMargin: "-10% 0%" });

  React.useEffect(() => {
    if (inView) {
      const t = new TimelineLite();

      t.addLabel("start", 0.5);

      if (refTitle.current) {
        t.fromTo(
          refTitle.current,
          1,
          { autoAlpha: 0, y: "40px" },
          { autoAlpha: 1, y: "0px", ease: "Power4.easeInOut" },
          "start"
        );
      }

      if (refImage.current) {
        t.fromTo(
          refImage.current,
          1.3,
          { autoAlpha: 0, y: "40px" },
          { autoAlpha: 1, y: "0px", ease: "Power4.easeInOut" },
          "start+=0.1"
        );
      }

      if (refText.current) {
        t.fromTo(
          refText.current,
          1.2,
          { autoAlpha: 0, y: "40px" },
          { autoAlpha: 1, y: "0px", ease: "Power4.easeInOut" },
          "start+=0.3"
        );
      }
    }
  }, [inView]);

  return (
    <Container>
      <div className={s.tap} ref={ref}>
        <Row>
          <div className={s.tap__introLeft} ref={refTitle}>
            <h1 className={s.tap__heading}>{title}</h1>

            <h2 className={s.tap__subheading}>Opening Hours</h2>
            <div className={s.tap__copy}>
              Thursdays, Fridays and Saturdays from
              <br />
              4:00PM - 11:00PM
            </div>

            <h2 className={s.tap__subheading}>Location</h2>
            <div className={s.tap__copy}>
              Skútuvogur 1H
              <br />
              104 Reykjavík
              <br />
              <br />
              <a
                href="https://www.google.com/maps/place/Malbygg+Taproom/@64.1399974,-21.8482579,17z/data=!3m1!4b1!4m5!3m4!1s0x48d6757a5525ee31:0xdbb0cf1d3bd28bd6!8m2!3d64.1399951!4d-21.8460639"
                target="_blank"
              >
                Get Map Direction
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 350 350"
                >
                  <path d="M173.106-1.944c-75.999 0-137.609 61.61-137.609 137.609 0 33.928 12.335 64.936 32.692 88.929l104.917 123.562L278.019 224.59c20.357-23.99 32.692-55 32.692-88.93.004-75.994-61.606-137.604-137.605-137.604zm0 189.462c-31.256 0-56.592-25.335-56.592-56.591s25.336-56.592 56.592-56.592 56.592 25.336 56.592 56.592-25.336 56.591-56.592 56.591z"></path>
                </svg>
              </a>
            </div>
          </div>
          <div className={s.tap__introRight}>
            <div className={s.tap__imageWrap} ref={refImage}>
              <Image
                className={s.tap__image}
                src={require("assets/images/tap03.jpg")}
              />
            </div>
          </div>

          <div className={s.tap__text} ref={refText}>
            {text}
          </div>
        </Row>
      </div>
    </Container>
  );
};
